<template>
  <div class="wh-space-bottom">
    <b-card>
      <table-search
        ref="search"
        v-model="filterValue"
        :fields="search_fields"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </b-card>

    <b-card>
      <table-header
        ref="header"
        :title="title"
        :button-actions="header_actions"
        @runSearch="runSearch"
        :hide-search="false"
        :search-tips="$t('call_bell.search_tips')"
      />
      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        :permission="view_permission"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
      />
    </b-card>
  </div>
</template>

<script>
import common from '@/common'
import TableContent from '@/components/TableContent'
import TableHeader from '@/components/TableHeader'
import TableSearch from '@/components/TableSearch'

export default {
  name: 'callBellEnvironment',
  components: {
    TableSearch,
    TableHeader,
    TableContent,
  },
  data() {
    return {
      title: common.getMenuName('callBellEnvironment'),
      view_permission: common.checkPermission('CallBell_Menu_Environment'),
      search_fields: [
        {
          field: 'isUAT',
          label: 'device_env.env',
          type: 'select',
          options: [
            {
              text: common.getI18n('device_env.env_uat'),
              value: true
            },
            {
              text: common.getI18n('device_env.env_production'),
              value: false
            },
          ]
        }
      ],
      filterValue: {},
      header_actions: [
        {
          text: 'common.export_device_list',
          variant: 'primary',
          fun: this.exportAllData,
          permission: 'CallBell_Menu_Environment'
        },
        {
          text: 'common.add',
          variant: 'primary',
          fun: this.addData,
          permission: 'CallBell_Menu_Environment'
        },
      ],
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: 'device_env.mac',
          field: 'deviceMAC',
          width: '180px',
          changeHTML: (value, row) => `<span class="wh-font-family-monospace">${value}</span>`
        },
        {
          label: 'call_bell.call_bell_name',
          field: 'deviceName',
          // width: '180px',
        },
        {
          label: 'device_env.env',
          field: 'isUAT',
          width: '120px',
          change: function (value) {
            switch (value) {
              case true:
                return common.getI18n('device_env.env_uat')
              case false:
                return common.getI18n('device_env.env_production')
              case null:
                return ''
            }
          }
        },
        {
          label: 'common.action',
          field: 'action',
          width: '200px',
          sortable: false,
        },
      ],
      table_actions: [
        {
          text: 'common.edit',
          variant: 'primary',
          fun: 'editData',
          permission: 'CallBell_Menu_Environment'
        },
        {
          text: 'common.delete',
          variant: 'danger',
          fun: 'deleteData',
          permission: 'CallBell_Menu_Environment'
        }
      ],
    }
  },

  activated() {
    this.getList()
  },
  deactivated() {
    this.$refs.search.resetFilterValueToFiltered()
  },

  methods: {
    getList: function () {
      if (!this.view_permission) return

      const api = '/callbell/GetCallBellList'
      const search = this.getSearchCondition()
      const url = common.getTableUrl(api, search, this.sort_condition, this.page_condition)

      common.apiGetData(url)
        .then(res => {
          this.total_rows = res.data.total
          this.rows = res.data.callBells
        })
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    exportAllData() {
      common.apiDownloadData({
        url: '/callbell/ExportCallBellList',
      })
    },
    runSearch() {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      this.getList()
    },
    searchChangeField() {
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    addData: function () {
      this.$router.push({ name: 'callBellEnvironment_add' })
    },
    editData: function (data) {
      if (data.isUAT == null) {
        data.isUAT = ' '
      }
      this.$router.push({
        name: 'callBellEnvironment_edit',
        params: {
          mac: common.encrypt(data.deviceMAC),
          env: common.encrypt(data.isUAT),
          name: common.encrypt(data.deviceName)
        }
      })
    },
    deleteData: function (data) {
      common.showConfirm({
        title: this.$t('common.delete_tips') + data.deviceMAC,
        confirm_fun: async () => {
          await this.$requestWehealth({
            url: '/callbell/DeleteCallBell',
            method: 'post',
            params: {
              deviceMAC: data.deviceMAC
            }
          })
          common.showToast({ title: this.$t('common.delete_success') })
          this.getList()
        }
      })
    },
    runTableAction: function (action, data) {
      this[action](data)
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
  }
}
</script>
